.container-form {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #29322d;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    z-index: 10;
  }
  
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    font-size: 16px;
    color: #e3d1b6;
  }
  
  input {
    height: 25px;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #e3d1b6;
    outline: none;
  }
  
  button {
    background-color: #e3d1b6;
    color: #29322d;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #202622;
    color: #e3d1b6;
  }
  
 
  .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }

  
  