.container-p-list {
  height: 50vh;
  overflow: scroll;
}

.container-newproduct {
  width: 100%;
  position: relative;
}

.form-dash {
  flex-direction: unset;
  display: flex;
}

.label-dash {
  display: inline-block;
  color: #29322d;
  font-size: smaller;
  display: flex;
  padding: 10px;
}

.label-dash > input {
  background-color: #f9f9f9;
}

.label-dash-list > input {
  background-color: #f9f9f9;
}

.label-dash-list > ul > li > button {
  margin-top: 0;
}

.label-dash-list > ul > li {
  display: table-cell;
}

.btn-add-dash {
  font-size: smaller;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 125px;
  bottom: -50px;
  background-color: #f9f9f9;
}

.column {
  flex: 50%;
}

.title-form {
  width: 100%;
  height: 38px;
  background-color: #29322d;
  color: #e3d1b6;
  text-align: center;
  font-size: smaller;
  font-weight: 700;
  line-height: 2.5;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: small;
}

thead {
    background-color: #29322d;
    color: #e3d1b6;
  }

tr:nth-child(even){background-color: #f2f2f2}

th {
  padding: 10px;
}

tbody {
  text-align: center;
}

tbody > tr {
  height: 100px;
}

td > input {
  text-align: center;
  font-size: small;
}

td > ul > li > input {
  text-align: center;
  font-size: small;
}

.btn-dash {
  font-size: small;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
  margin: 5px;
}

.hide {
  display:none;
}

.spc {
  margin-top: 10px;
}


@media only screen and (orientation: portrait) {
  .form-dash {
    flex-direction: column;
  }

  .column {
    width: 80%;;
  }

  .label-dash > textarea {
    width: 100%;
  }
}



  